import React, { useState } from "react";
import "./style.css";
import logout from "../../assets/switch.png";
import api from '../../services/api';

function Navbar({ setSwitcher, showSwitcher ,roomId,setRoomId}) {


    const privateChat = async () => {
		const response = await api.privateChat({ roomId });
		console.log(response.data);
		alert("Conversation has been made private!");
	};

	const resolveChat = async () => {
		const response = await api.resolveChat({ roomId });
		console.log(response.data);
		alert("Conversation has been resolved now!");
	};

	const startRDPDesktop = async () => {
		const response = await api.startRDP({
			roomId,
			deviceType: "desktop",
		});
		console.log(response.data);
		window.open(response.data.data.connectUrl);
	};

	const startRDPMobile = async () => {
		const response = await api.startRDP({
			roomId,
			deviceType: "phone",
		});
		console.log(response.data);
		alert(
			"Steps to start rdp session has been sent to the user through chat"
		);
	};

	return (
		<>
			<div className="navbar">
				{/* {showSwitcher?<button className="navbar-item-btn" onClick={()=>{
                setSwitcher('CHATS')
                }}>Chats</button>: null} */}
				{/* <button className="navbar-item-btn" onClick={()=>{
                setSwitcher('CALLS')
                }}>Calls</button> */}
				<div style={{width:"100%",display:"flex",alignItems:"center"}}>
					<div style={{display:"flex",alignItems:"center",marginLeft:"60px"}}>
						<div>Logout</div>
						<button
							className="navbar-item-img-btn"
							onClick={() => {
								localStorage.clear();
								window.location = "/";
							}}>
							<img src={logout} width={25} height={25} />
						</button>
					</div>
					<div style={{display:"flex",alignItems:"center",marginLeft:"7%"}}>
						{roomId !== "" ? (
						<div
							visib

							className="header-top__navigationchat">
							<div className="navbarchat">
								<button
									className="navbar-item-btnchat"
									onClick={() => {
										startRDPDesktop();
									}}>
									Start RDP(Desktop)
								</button>
								<button
									className="navbar-item-btnchat"
									onClick={() => {
										startRDPMobile();
									}}>
									Start RDP(Mobile)
								</button>
								<button
									className="navbar-item-btnchat"
									onClick={() => {
										privateChat();
									}}>
									Chat Private
								</button>
								<button
									className="navbar-item-btnchat"
									onClick={() => {
										resolveChat();
									}}>
									Chat Resolve
								</button>
							</div>
						</div>
						) : null}
					</div>
				</div>
				<hr className="header-top__seperatorchat" />
			</div>
		</>
	);
}

export default Navbar;
