import React, { useEffect, useState } from 'react';
import AgoraUIKit, {layout} from 'agora-react-uikit';
import {useParams} from 'react-router-dom';
import api from '../../services/api';


function CallScreen(props) {

    const {channelName} = useParams();
    const [rtcProps, setRtcProps] = useState({});
    const [styleProps, setStyleProps] = useState({});
    const [videoCall, setVideoCall] = useState(true);

    useEffect(()=> {
        console.log(channelName);
        getChannelToken();
    }, [])

    const getChannelToken=async()=>{
        const response = await api.getChannelToken({channelName});
        console.log(response.data);
        let rtcProps = {
            appId: "dbfcd74a4a9a4edfa3620124777bc877",
            channel: response.data.channelName,
            token: response.data.rtcToken,
            layout: layout.pin,
            enableVideo: true,
        }
        let styleProps = {
            videoMode: {max: "contain", min: "contain"}
        }
        setRtcProps(rtcProps);
        setStyleProps(styleProps);
    }

    return (
        <>
        {rtcProps.token?
        <>{ videoCall?
        <div>
            <div style={{ display: "flex", width: "100vw", height: "100vh" }}> 
                <AgoraUIKit 
                    rtcProps={rtcProps} 
                    styleProps={styleProps}
                    callbacks={{
                        EndCall: () => setVideoCall(false)
                    }}/> 
            </div> 
        </div>: 
        <div>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <p>Your call has ended!</p>
            </div>
        </div>
        }</>: <></>}
        </>
    )
}

export default CallScreen
