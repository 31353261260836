import React, { useState } from 'react';
import Navbar from '../Navbar' ;
import './style.css';

function Header ({setSwitcher, showSwitcher,roomId,setRoomId}) {


    return (
      <section className="header" style={{marginTop:"-7px"}}>
        <section className="header-top">
          <section className="header-top__navbar">
            <section className="header-top__navigation">
              <Navbar setSwitcher={setSwitcher} showSwitcher={showSwitcher} roomId={roomId} setRoomId={setRoomId}/>
            </section>
            {/* <hr className="header-top__seperator" /> */}
          </section>
        </section>
      </section>
    )
}

export default Header;