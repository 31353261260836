import React, {useState } from 'react'
import Header from '../Header';
import Chat from '../Chat';
import Calls from '../Calls';
import Agent from '../Agent';


function Dashboard(props) {

    const [switcher, setSwitcher] = useState('CHATS');
    const [roomId, setRoomId] = useState('');
    console.log('props--->',props)
    const setRoomIdFunction = (id) =>{
        setRoomId(id);
    }
    
    return (
        <div className="container">
            <Header setSwitcher={setSwitcher} showSwitcher = {!props.isAdmin} roomId={roomId} setRoomId={setRoomIdFunction}/>
            {props.isAdmin? <Agent/> : 
            <>
                {switcher === 'CHATS' ? <Chat username={props.username} agoraToken={props.agoraToken} roomId={roomId} setRoomId={setRoomIdFunction}></Chat>: <Calls/>}
            </>
            }
        </div>
    )
}

export default Dashboard
