import {Button} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import './style.css';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {  useState,useEffect } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {phone} from 'phone';



const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  phone: yup.string().required('Phone is required'),
  email: yup.string().email().required('Email is required'),
  password: yup.string().min(8,'password must be 8 charcter').max(32).required('Password is required'),
});


function Agentform(props) {
  var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

   const {handleShow,handleClose,show,agentFormSubmit } =props;
   const { register,setValue, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const handleChange =  (data) =>{
    const phonWithCountry='+'+data;
    const {phoneNumber,countryCode,isValid} =phone(phonWithCountry);
    if(isValid){
      const phonwWithoutCountry = phoneNumber.replace(countryCode,'');
      setValue("phone", phonwWithoutCountry);
      setValue("country_code", countryCode);
    }
  }

  
   return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <form onSubmit={handleSubmit(data => agentFormSubmit(data, reset))}>
        <Modal.Header closeButton>
          <Modal.Title> Add Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input type="text" className="form-control" id="name" placeholder="Fullname" name='name' {...register("name")}/>
                <span className='error'>{errors.name?.message}</span>
            </div>
            <div className="form-group">
                <label htmlFor="Email">Email address</label>
                <input type="email" className="form-control" id="email" placeholder="Email" name='email' {...register("email")}/>
                <span className='error'>{errors.email?.message}</span>
            </div>
            <div className="form-group">
                <label htmlFor="phone">phone</label>
                <PhoneInput
                  country={'in'}
                  name='phone'
                  onChange={(data) =>handleChange(data)}            
                />
                <input type="hidden" className="form-control" id="country_code" placeholder="country_code" name='country_code' {...register("country_code")}/>
                <span className='error'>{errors.phone?.message}</span>
            </div>
            <div className="form-group">
                <label htmlFor="password">Password</label>
                <input type="password" className="form-control" id="password" placeholder="Password" name='password' {...register("password")}  />
                <span className='error'>{errors.password?.message}</span>
            </div>
        
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-sm btn-danger float-right btn-fw" onClick={handleClose}>Close</Button>
          <Button type='submit' className="btn-sm btn-success float-right btn-fw">Submit</Button>
        </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default Agentform;