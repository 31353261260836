

module.exports.getAuthenticationDetails =  () => JSON.parse(localStorage.getItem('data'));
module.exports.getUserToken = () =>{
    let data  = this.getAuthenticationDetails();
    let { token} = data;
    token='jwt '+token;
    return token;
}

