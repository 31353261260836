import React, { useEffect, useState } from "react";
import { EaseApp } from "agora-chat-uikit";
import "./style.css";
import { Modal } from "react-bootstrap";
import messageSound from "../../assets/sounds/message.mp3.mp3";
import videoCallSound from "../../assets/sounds/videoCallSound.wav";

function Chat(props) {
	const [isPopUp, setIspopUp] = useState(false);
	const [videoLink, setVideoLink] = useState("");
	const { roomId, setRoomId } = props;
	console.log("chat=========>");

	const handleonConversationClick = (session) => {
		console.log(session, "handleonConversationClick");
		console.log("text recieved");
		const { sessionType, sessionId } = session;
		setRoomId(sessionId);
	};

	const WebIM = EaseApp.getSdk({ appkey: "411029935#1202964" });
	// Use nameSpace to differentiate the different business logics. You can add multiple callback events according to your needs.。
	WebIM.conn.addEventHandler("nameSpace", {
		onOpend: () => {
			console.log("opened");
		},
		onTextMessage: (msgt) => {
			if (msgt.from === "admin") {
				setVideoLink(msgt.msg);
				setIspopUp(true);
				const sound = new Audio(videoCallSound);
				sound.play();
				//         <a href= msgt.msg
				// onclick="return !window.open(this.href, 'Google', 'width=500,height=500')"
				// target="_blank">
			} else {
				// if (!document.hasFocus()) {
					const sound = new Audio(messageSound);
					sound.play();
				// }
			}
			console.log("MSGT", msgt.from);
		},
	});

	return (
		<div style={{ width: "100%", height: "90vh"}}>
			{isPopUp && (
				<div
					style={{
						padding: "20px",
						overflowY: "auto",
						fontSize: "24px",
						width: "100vw",
					}}>
					<div
						style={{
							position: "absolute",
							zIndex: 10000,
							top: "2%",
							right: "30vw",
							background: "white",
							boxShadow:
								"0 0 10px rgba(0, 0, 0, 0.3)",
							borderRadius: "8px",
							padding: "20px",
							overflowY: "auto",
							width: "440px",
							fontSize: "24px",
						}}>
						<p style={{ fontSize: "15px" }}>
							Join this channel :
							<a
								href={videoLink.substring(
									videoLink.indexOf("https")
								)}
								target="_blank"
								rel="noreferrer">
								{videoLink.substring(
									videoLink.indexOf("https")
								)}
							</a>
						</p>
						<div
							id="popUpButton"
							style={{
								boxSizing: "border-box",
								height: "40px",
								width: "80px",
								textAlign: "center",
								padding: "10px",
								fontSize: "14px",
								textDecoration: "none",
								cursor: "pointer",
								border: "2px solid #007bff",
								borderRadius: "5px",
								backgroundColor: "#007bff",
								color: "#fff",
								marginTop: "30px",
							}}
							onClick={() => setIspopUp(false)}>
							Cancel
						</div>
					</div>
				</div>
			)}
			<div style={{width:'100%',overflow:"scroll"}}>
				<EaseApp
					className="easeapp"
					appkey="411029935#1202964"
					username={props.username}
					agoraToken={props.agoraToken}
					isShowUnread={true}
					unreadType={true}
					onConversationClick={handleonConversationClick}
				/>
			</div>
		</div>
	);
}

export default Chat;
