import React from 'react';
import { Link } from 'react-router-dom';
import { ConfirmToast } from 'react-confirm-toast';
import { FaTrashAlt } from 'react-icons/fa';



function AgentList(props) {
    const { agentlistData,destroyAgent} = props;
    const agentlist =  agentlistData.map((item,index)=>{
       return <tr key={index}>
        <td className="py-1">{index+1}</td>
        <td>{item.name}</td>
        <td>{item.phone}</td>
        <td>{item.email}</td>
        <td>{item.role}</td>
        <td>{item.email_verified ? <span class="badge bg-success">Yes</span>: <span class="badge bg-danger">No</span>}</td>
        <td>{item.createdAt}</td>
        <td>
          <ConfirmToast
            asModal={true}
            childrenClassName='margin-top-10'
            customCancel='Cancel'
            customConfirm='Confirm'
            customFunction={()=>{
              destroyAgent(item._id)
            }}
            message='Do you want to delete Agent?'
            position='top-left' //will be ignored cause asModal=true
            showCloseIcon={false}
            theme='lilac'
          >
            <span className="badge bg-danger"><FaTrashAlt /></span>
             
          </ConfirmToast>
        </td>
      </tr>
    })
  return (
   
    <div className="table-responsive">
    <table className="table">
      <thead>
        <tr>
          <th>
            No.
          </th>
          <th>
            Name
          </th>
          <th>
            Phone
          </th>
          <th>
            Email
          </th>

          <th>
            Role
          </th>
          <th>
            Email Verified
          </th>
          <th>
           CreatedAt
          </th>
          <th>
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {agentlist} 
      </tbody>
    </table>
  </div>
  )
}

AgentList.propTypes = {}

export default AgentList
