import React, { useEffect, useState } from 'react'

function Calls(props) {

    console.log('call=========>');

    return (
        <div>
            <p>textinnnn</p>
        </div>
    )
}

export default Calls
