import React, { useEffect, useState} from 'react'
import "./style.css";
import api from '../../services/api'
import logo from '../../assets/logo.png';
import Dashboard from '../Dashboard';

export default function AgentLogin() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agoraToken, setAgoraToken] = useState('');
  const [username, setUsername] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
     let data = JSON.parse(localStorage.getItem("data"));
     if(data && data?.token){
      if(data?.data._doc.role == 'superadmin'){
        setIsAdmin(true);
      } else {
        setAgoraToken(data?.agoraToken?.accessToken);
        setUsername(data?.agoraToken?.username);
      }
     }
  }, [])

  const login = async(e)=>{
    e.preventDefault();
    const response = await api.login({email, password});
    console.log(response);
    if(response.data?.data._doc.role == 'superadmin'){
      setIsAdmin(true);
    } else {
      setAgoraToken(response.data?.agoraToken?.accessToken);
      setUsername(response.data?.agoraToken?.username);
    }
    
    localStorage.setItem("data", JSON.stringify(response.data));
  }
  
  return (

    <>
    {(agoraToken == '' || username == '') && !isAdmin ? <>
    <div className='loginContainer'>
      <div className="min-h-screen text-slate-800 grid grid-cols-1 bg-white md:p-6">
        <div className="col-span-1 md:col-span-1 flex justify-center items-center h-full p-4 md:p-6 text-center">
          <div className="w-4/5 md:w-3/5">
            <form onSubmit={login} className='loginForm'>
              <div className="font-bold text-lg flex justify-center items-center gap-1 my-4">
                <div className="flex justify-center"><img src={logo} width={100} height={100} /></div>
              </div>
              <div className="logintext">Login</div>
              <div className="emailDiv">
                <div className="emailtext">Email address</div>
                <label >
                  <input id="email" type="email" className="w-full bg-[#fafafa] rounded-lg text-base text-[#673ab7]" placeholder="Enter Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
              </div>
              <div className="text-left my-4">
                <div className="emailtext">Password</div>
                <label >
                  <input type="password" className="w-full bg-[#fafafa] rounded-lg text-base text-[#673ab7]" placeholder='Enter Password' required value={password} onChange={(e) => setPassword(e.target.value)} />
                </label>
              </div>
              <div><button type="submit" className="w-full py-2 text-white bg-[#673ab7] rounded font-medium mt-10 hover:bg-[#563199] flex justify-center h-10" onClick={login}>Login</button></div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>: <>
      <Dashboard username={username} agoraToken = {agoraToken} isAdmin = {isAdmin}/>
    </>}
    </>
  )
}
