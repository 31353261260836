import React, {Component} from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AgentLogin from "./containers/AgentLogin";
import CallScreen from './containers/CallScreen';
import './App.css';

class App extends Component {
  
  render() {
    return (
     <BrowserRouter>
      <Routes>
        <Route path='/' element={<AgentLogin />} />
        <Route path='/agent-login' element={<AgentLogin />} />
        <Route path="call/:channelName" element={<CallScreen />} />
      </Routes>
     </BrowserRouter>
    );
  }
}

export default App;