import axios from "axios";
import config  from "../config/config";
import utility from "../utils/utility";



const login = async data =>{
    // console.log('config---------->config',config)
    const response = await axios({
        url: config.apiUrl+"/auth/login",
        method: 'post',
        data,
    });
    return response;
}
const getChannelToken = async data =>{
    const loginData = JSON.parse(localStorage.getItem('data'));
    const response = await axios({
        url: config.apiUrl+"/token/getchanneltoken",
        method: 'post',
        headers: {
            authorization: "jwt "+loginData.token,
        },
        data,
    });
    return response;
}
const privateChat = async data =>{
    const loginData = JSON.parse(localStorage.getItem('data'));
    const response = await axios({
        url: config.apiUrl+"/agora/makeprivatechat",
        method: 'post',
        headers: {
            authorization: "jwt "+loginData.token,
        },
        data,
    });
    return response;
}

const resolveChat = async data =>{
    console.log('config---------->config',config)
    const loginData = JSON.parse(localStorage.getItem('data'));
    const response = await axios({
        url: config.apiUrl+"/agora/chatresolved",
        method: 'post',
        headers: {
            authorization: "jwt "+loginData.token,
        },
        data,
    });
    return response;
}
const getAgentList = async data =>{
    const loginData = JSON.parse(localStorage.getItem('data'));
    const response = await axios({
        url: config.apiUrl+"/agora/getallagents",
        method: 'get',
        headers: {
            authorization:  "jwt "+loginData.token,
        },
        data,
    });
    return response;
}
const createAgent = async data =>{
    const loginData = JSON.parse(localStorage.getItem('data'));
    try {
        const response = await axios({
            url: config.apiUrl+"/auth/signup",
            method: 'post',
            headers: {
                authorization:  "jwt "+loginData.token,
            },
            data:{
                ...data,
                role:'agent'
            },
        });
        return response.data;
        
    } catch (error) {
        return error.response.data;        
    }

}
const destroyAgent = async id =>{
    console.log('authorization',utility.getUserToken());
    try {
        const response = await axios({
            url: config.apiUrl+"/agora/deleteagent/"+id,
            method: 'delete',
            headers: {
                authorization: utility.getUserToken(),
            }
        });
        return response.data;
        
    } catch (error) {
        return error.response.data;        
    }

}
const startRDP = async data =>{
    console.log('config---------->config',config)
    const loginData = JSON.parse(localStorage.getItem('data'));
    const response = await axios({
        url: config.apiUrl+"/startRdp",
        method: 'post',
        headers: {
            authorization: "jwt "+loginData.token,
        },
        data,
    });
    return response;
}



const api ={
    login,
    getChannelToken,
    privateChat,
    resolveChat,
    getAgentList,
    createAgent,
    destroyAgent,
    startRDP
}
export default api




