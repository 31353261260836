import React, { useState,useEffect } from 'react'
import AgentList  from "./AgentList";
import api from '../../services/api';
import Agentform from "./Agentform";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function Agent(props) {
  const [show, setShow] = useState(false);
  const [agentlistData, setAgentlistData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const agentFormSubmit = async (formData,reset) =>{
    const data = await api.createAgent(formData);
    data.statusCode!==200 && toast.error(data.message);
    if(data.statusCode===200){
      const item=data.data._doc;
      const agent = {
        name:item.name,
        phone:item.phone,
        email:item.email,
        role:item.role,
        email_verified:item.email_verified,
        createdAt:item.createdAt,
      }
      setAgentlistData([...agentlistData,agent]);
      toast.success('Agent is created successfully');
      handleClose();
      reset();
    }; 
  }
  const destroyAgent = async (id) =>{
    await api.destroyAgent(id);
    setAgentlistData(agentlistData.filter(item=>item._id!==id));
    toast.success('Agent is deleted successfully'); 
  }
  useEffect(() => {
      async function fetchAgentlistApi(){
      const data  = await api.getAgentList();
      data && setAgentlistData(data.data.data)
      }
      fetchAgentlistApi();
  }, [])
    
  return (
  <div className="container-scroller">
   <ToastContainer />  {/* page-body-wrapper start */}
  <div className="container-fluid page-body-wrapper">
    {/* main-panel start */}
    <div className="main-panel">
      {/* content-wrapper start */}
      <div className="content-wrapper">
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title">
                  Agent
                  <button type="button" class="btn-sm btn-primary float-right btn-fw" style={{float:'right'}} onClick={handleShow}> <span><i class="mdi mdi-plus"></i></span>Add Agent</button>
                  </h4>
                <Agentform show={show} handleClose={handleClose} handleShow={handleShow} agentFormSubmit={agentFormSubmit} />
                <AgentList agentlistData={agentlistData} destroyAgent={destroyAgent} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* content-wrapper ends */}
      {/* main-panel end */}
    </div>
    {/* main-panel ends */}
  </div>
  {/* page-body-wrapper end */}
</div>


)
}


export default Agent
